import { render, staticRenderFns } from "./kuisioner-4-alt.vue?vue&type=template&id=76cdfc87&scoped=true&"
import script from "./kuisioner-4-alt.vue?vue&type=script&lang=js&"
export * from "./kuisioner-4-alt.vue?vue&type=script&lang=js&"
import style0 from "./kuisioner-4-alt.vue?vue&type=style&index=0&id=76cdfc87&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76cdfc87",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText, CHeading: require('@chakra-ui/vue').CHeading, CBox: require('@chakra-ui/vue').CBox, CFormLabel: require('@chakra-ui/vue').CFormLabel, CFormHelperText: require('@chakra-ui/vue').CFormHelperText, CFormControl: require('@chakra-ui/vue').CFormControl, CButton: require('@chakra-ui/vue').CButton, CGrid: require('@chakra-ui/vue').CGrid})
